<template>
  <v-row class="justify-md-space-around justify-center align-center">
    <v-col class="d-none d-md-flex" cols="4">
      <v-img  src="../assets/login.svg"></v-img>
    </v-col>
    <v-col xs="12" sm="12" md="6">
      <v-card class="elevation-12">
        <v-toolbar color="white" light flat>
          <v-toolbar-title>Connexion à votre compte</v-toolbar-title>
        </v-toolbar>
        <v-form ref="form" v-on:submit.prevent v-model="valid">
          <v-card-text>
            <FormAlert :info="{alert}"/>
          <v-text-field
          label="Email"
          name="email"
          prepend-icon="mdi-at"
          type="text"
          v-model="user.email"
          :rules="this.rules.email"
          ></v-text-field>
          <v-text-field
          id="password"
          label="Mot de passe"
          name="password"
          prepend-icon="mdi-lock"
          :append-icon="passwordIsVisible ? 'mdi-eye' : 'mdi-eye-off'"
          :type="passwordIsVisible ? 'text' : 'password'"
          @click:append="passwordIsVisible = !passwordIsVisible"
          v-model="user.password"
          :rules="this.rules.password"
          ></v-text-field>

        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <div class="d-none d-md-flex">
            <v-btn type="submit" :disabled="!valid" color="primary" @click="submit()" outlined>
              Se connecter
            </v-btn>
          </div>
          <div class="d-xs-flex d-sm-flex d-md-none">
            <v-btn type="submit"
            :disabled="!valid" x-small color="primary" @click="submit()" outlined>
              Se connecter
            </v-btn>
          </div>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-snackbar top color="black" center v-model="snackbar">
      Vous devez être connectés pour accéder à cette page
      <v-btn color="white" text @click="snackbar = false">Fermer</v-btn>
    </v-snackbar>
  </v-col>
</v-row>
</template>

<script>

import MixinUsers from '@/mixins/users';
import FormAlert from '@/components/common/forms/Alert.vue';

export default {
  name: 'Login',
  mixins: [MixinUsers],
  components: { FormAlert },
  data: () => ({
    passwordIsVisible: false,
    user: { email: '', password: '' },
    valid: true,
    snackbar: false,
  }),

  mounted() {
    if (this.$route.query.authenticationNeeded) {
      this.snackbar = true;
    }
    this.$emit('user-is-connected', false);

    this.user.email = this.$route.query.u || '';
  },

  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.authenticateUser(this.user);
      }
    },
  },
};
</script>
